import React from "react"
//import ReactDOM from "react-dom"
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import WineContainer from "./components/WineContainer"

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <BrowserRouter>
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/css/bootstrap.min.css"
        integrity="sha384-Zenh87qX5JnK2Jl0vWa8Ck2rdkQ2Bzep5IDxbcnCeuOxjzrPF/et3URy9Bv1WTRi"
        crossOrigin="anonymous"
      />
      <link
        rel="stylesheet"
        href="custom.css"
      />
      <link 
        href="https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100&display=swap"
        rel="stylesheet"
      />  
        <WineContainer />
    </BrowserRouter>,
  
)

