import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Carousel from 'react-bootstrap/Carousel'
import { Link, useLocation} from 'react-router-dom'

const WineReco = (props) => {

  const location = useLocation();
  const carouselImageSource = ["wine-carousel.jpg","grapes.jpg","wine-barrel.jpg","wine-bottle.jpg","wine-warehouse.jpg","hut.jpg"];
  const carouselImages = shuffle(carouselImageSource);
  const [index, setIndex] = useState(0);
  
  let [ recoWines, setRecoWines ] = useState([]);
  let [ isUpdated, setIsUpdated ] = useState(false);
  let wineName = new URLSearchParams(location.search).get('wineName');

  function shuffle(array) {
    return array.sort(() => Math.random() - 0.5);
  }

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  //let params = useParams();
  
  useEffect(() => {
    if (isUpdated === true) {
     return;
    }
    setIsUpdated(true);
    
    axios
    .get(`/getwinereco?wineName=${wineName}`)
      .then(
          response => {response.data.forEach(wine => {
            setRecoWines(recoWines => [...recoWines,wine]);
      })});
  },[recoWines, isUpdated, wineName]);

  const showCarousel = () => {
    if (recoWines.length === 0) {
      return (
        <h4>No one else has yet liked this wine</h4>
      )
    }
    else {
      return (
        <Row xs={2}>
          <Carousel activeIndex={index} onSelect={handleSelect}>
            { recoWines.map(wine => (
                <Carousel.Item key={wine.wineId}>
                  <img
                    className="d-block w-120 img-fluid rounded"
                    src={carouselImages[index]}
                    alt={wine.name}
                  />
                  <Carousel.Caption>
                  <div className="content">
                    <Link to={{pathname:"/wineDisplay",search: "?wineId=" + wine.wineId}}><h3>{wine.name}</h3></Link>
                    <p className="d-block w-120">Color: {wine.color}&nbsp;Country: {wine.country}</p>
                  </div>
                  </Carousel.Caption>   
                </Carousel.Item>
              ))}
          </Carousel>
        </Row>
      )
    }
  }

  return (
    <div>
      <hr className='topbottom'/>
      <h3>Recommendations if you liked {wineName}</h3>
      <p />
      {showCarousel()}
      <hr className='topbottom'/>
      <p />
        <Link to="/">
          <Button variant="primary">Home</Button>
        </Link>
    </div>
  );
}
export default WineReco;