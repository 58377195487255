import React, { useState, useEffect } from 'react'
import axios from 'axios'
import ListGroup from 'react-bootstrap/ListGroup'
import { Link } from 'react-router-dom'

const ListNews = () => {
  let [ news, setNews ] = useState();
  let [ wineEvents, setWineEvents ] = useState();
  let [ isUpdated, setIsUpdated ] = useState(false);

  const showNews = () => {
   if (news) {
     return (
       news.map(oneNews => (
         <ListGroup.Item key={oneNews.newsId} className="pl-0">
           <h5>{oneNews.headLine}</h5>
           <p />
           <span dangerouslySetInnerHTML={{ __html: oneNews.bodyText}} />
           <br />
           <div className="news-publishdate">
            Published on: {oneNews.creationDate}
           </div>
         </ListGroup.Item>
       ))
     )
   }
   else {
     return (
       <ListGroup.Item>
         No recent news but things happen all the time in the world of wines.
       </ListGroup.Item>
     )
   }
  }

  const showWineEvents = () => {
    if (wineEvents) {
      return (
        wineEvents.map(oneWine => (
          <ListGroup.Item key={oneWine.wineId} className="pl-0">
            <h5>{oneWine.name}</h5>
            Read about <Link to={{pathname:"/wineDisplay",search: "?wineId=" + oneWine.wineId}}>{oneWine.name}</Link>
            <div className="news-publishdate">
             Published on: {oneWine.creationDate}
            </div>
          </ListGroup.Item>
        ))
      )
    }
  }

  useEffect(() => {
    if (isUpdated === true) {
      return;
     }
    setIsUpdated(true);
    axios
     .get(`/wines/latest/30`)
      .then(response => setWineEvents(response.data));

    axios
      .get(`/getnews`)
        .then(response => setNews(response.data));   
  },[news,wineEvents,isUpdated]);

  return(
    <div className="news">
      <h4>News</h4>
      <ListGroup className="list-group-flush">
        {showNews()}
      </ListGroup>
      <ListGroup className="list-group-flush">
        <h4>Latest wines added</h4>
        {showWineEvents()}
      </ListGroup>
    </div>
  );
}

export default ListNews;