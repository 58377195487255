import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'

const WineReviews = () => {
  
  let [ isUpdated, setIsUpdated ] = useState(false);
  
  let [ reviews, setReviews ] = useState([]);

  const showWineReviews = () => {
    return (
      <ul className="list-group">
        {reviews.map(wineReview => (
          <ol className="list-group-item border-0" key={wineReview.reviewId}>
            <h4>{wineReview.wineName}</h4>
            {wineReview.reviewText}
            <br />
            <Link to={{pathname:"/winereview", search: "?reviewId=" + wineReview.reviewId + "&wineName=" + wineReview.wineName}}>Read review for {wineReview.wineName}</Link>
            <br />
            <Link to={{pathname:"/wineDisplay",search: "?wineId=" + wineReview.wineId}}>Read about {wineReview.wineName}</Link>
          </ol>
        ))}
      </ul>
    )
  }
  
  useEffect(() => {
    if (isUpdated === true) {
      return;
     }
    setIsUpdated(true);
    axios
      .get(`/getwinereviews`)
        .then(response => response.data.forEach(wineReview => {
          setReviews(reviews => [...reviews, wineReview])
        }));
  },[reviews,isUpdated]);


  return (
    <div>
      <hr />
      <h3>Wine reviews</h3>
      {showWineReviews()}
      <p />
      <div className="row">
        <Link to="/">
          <Button variant="primary" className="btn ml-3">Home</Button>
        </Link>
      </div>
      <hr />
    </div>
  );
}
export default WineReviews;

