import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Button from 'react-bootstrap/Button'
import { checkValueInArray, wineDefault } from './Resources'
import { Link, useLocation} from 'react-router-dom'

const WineDisplay = (props) => {
  
  let [ isUpdated, setIsUpdated ] = useState(false);
  let [ wine, setWine ] = useState(wineDefault);
  let [ knowsCount, setKnowsCount ] = useState(0);
  let location = useLocation();
  let wineId = new URLSearchParams(location.search).get('wineId');
  let userEmail = props.userEmail;

  const addUserLogMessage = message => {
    props.addUserLog({message: message});
  }

  const showReviewLikeButtons = () => {
    if (userEmail !== 'none') {
      return (
        <div>
          <Link to={{pathname:"/winereviewadd", wineid: "?wineId=" + wineId, winename: "&wineName=" + wine.name}}>
            <Button variant="primary" className="btn ml-3">Add a review of {wine.name}</Button>
          </Link>
          <Button variant="primary" className="btn ml-3" onClick={addLikeJson}>Like {wine.name}</Button>
        </div>
      )
    }
  }

  const addLikeJson = () => {
    let like = {
      "reporteremail": `${props.userEmail}`,
      "winename": `${wine.name}`
    }
    axios
      .post("/addlike", like)
      .then(response => {
        if (response.status === 200) {
          addUserLogMessage(`Liked wine ${wine.name}`);
        }
        else {
          console.log(`Failed to add like for wine, error: ${response.status} ${response.statusText}`);
          addUserLogMessage(`Failed to add like for wine ${wine.name}`)
        }
      }).catch(err => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (isUpdated === true) {
      return;
     }
    setIsUpdated(true);

    axios
      .get(`/getwinebyid?wineId=${wineId}`)
        .then(response => setWine(response.data));
    
    axios
      .get(`/getpersonslikeswinecount?wineId=${wineId}`)
       .then(response => setKnowsCount(response.data));
  },[wine,isUpdated,wineId]);

  const isSparkling = (array,value) => {
    if (checkValueInArray(array,value)) {
      return "Sort: sparkling";
    }
  }

  const checkIfLinkExists = (externalLink) => {
    console.log("Value passed to linkExists: ", externalLink);
    if (externalLink) {
      return (
      <p>
        External links:
        <br />
        <a href={externalLink}>{externalLink}</a>
      </p>
      )
    }
  }

  return (
    <div>
      <hr />
      <h2>Wine name: {wine.name}</h2>
      <p />
      Color: {wine.color} 
      <p />
      {isSparkling(wine.tags,"sparkling")}
      <p />
      Country: {wine.country}
      <p />
      Year: {wine.year}
      <p />
      Grapes: {wine.grapes.join(' ')}
      <br />
      <Link to="/flavors">Read about grapes</Link>
      <p />
      Description:
      <p>{wine.description}</p>
      {checkIfLinkExists(wine.externalUrl)}
      <p />
      Liked by:
      <p><span role='img' aria-label='grape'>&#127815; {knowsCount}</span></p>
      <p />
      <Link to={{pathname:"/winereco", search: "?wineName=" + wine.name}}>See what others that liked this wine like</Link>
      <p />
      <div className="row">
        <Link to="/">
          <Button variant="primary"className="btn ml-3">Home</Button>
        </Link>
        
        {showReviewLikeButtons()}
      </div>
      <hr />
    </div>
  );
}
export default WineDisplay;
