import React, { useState, useEffect } from 'react'
import ListGroup from 'react-bootstrap/ListGroup'
import ListNews from './pages/ListNews'
import axios from 'axios'
import Button from 'react-bootstrap/Button'
import { useNavigate } from 'react-router-dom'

const WineHome = (props) => {

  let [ wine, setWine ] = useState();
  let [ isUpdated, setIsUpdated ] = useState(false);
  let navigate = useNavigate();

  const getWine = (wineName) => {
    axios
      .get(`/getwine?name=${wineName}`)
        .then(response => {
          navigate(`/wineDisplay/?wineId=${response.data[0].wineId}`);
        });
  }

  const getTopWines = () => {
    
    if (wine) {
      return (
        wine.map(onewine => (
          <ListGroup.Item key={onewine.wineName} className="pl-0">
            <Button variant="link" className="pl-0" onClick={() => getWine(onewine.wineName)}>{onewine.wineName}</Button>
            <span role='img' aria-label='grape'>&#127815; {onewine.likeCount}</span>
          </ListGroup.Item>
        ))
      )
    }
  }

  useEffect(() => {
    if (isUpdated === true) {
      return;
    }
    setIsUpdated(true);
    let topWines = [];
    axios
      .get(`/gettop3likes`)
        .then(response => {
          response.data.forEach(element => {
            let wine = {
              wineName: Object.keys(element),
              likeCount: Object.values(element)
            }
            topWines.push(wine);
          });
          setWine(topWines);
        });
          
    
  },[wine,isUpdated]);

  return (
    <div className="Home">
      <hr className='topbottom'/>
      <div className="row">
        <div className="col-4">
          <img src="front-wine-tasting.jpg" className="img-fluid rounded" alt="..." />
        </div>
        <div className="col-4 mx-auto">
          <h3>Welcome to Wine Cave</h3>
          On this site you can rate and review wines that your have tasted.
          You can also get recommendations based on what others like.
          < p/>
          <ListNews />
        </div>
        <div className="col-4">
          <h4>Most liked wines right now</h4>
            <img src="front-pic.jpg" className="img-fluid rounded" alt="..." />
            <p/>
            <ListGroup className="list-group-flush">
            {getTopWines()}
            </ListGroup>
        </div>
      </div>
      <hr className='topbottom'/>
    </div>
  );
}

export default WineHome;