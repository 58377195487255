import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Button from 'react-bootstrap/Button'
import { useKeycloak } from '@react-keycloak/web'
import { Link } from 'react-router-dom'

// https://gist.github.com/ulises-jeremias/a27b85e3eea083278188f24de955989b

const AuthRequest = () => {
  
  const {keycloak, initialized} = useKeycloak();
  const [authMessage, setAuthMessage] = useState("No message yet");
  const [userInfo, setUserInfo] = useState({});
  const [notification, setNotification] = useState({message: 'none'});
  
  useEffect(() => {
    if (!initialized) {
      return;
    }

    const fetchUserInfo = async () => {
      try {
        const userProfile = await keycloak.loadUserProfile();
        console.log("email: ", userProfile.email);
        console.log("username:", userProfile.username);
        console.log("id: ", userProfile.id);
        setUserInfo({ ...userProfile, fullName: `${userProfile.firstName} ${userProfile.lastName}` });
      } catch (err) {
        setNotification({message: err.message });
      }
    };

    if (keycloak.authenticated) {
      fetchUserInfo();
    }

    axios
      .get('/secured')
        .then(message => {
          setAuthMessage(message.data);
        });
  },[keycloak,initialized]);

  return (
    <div>
      <hr className='topbottom'/>
      <h3>Authenticated BE return message: {authMessage}</h3>
      <p />
      <hr className='topbottom'/>
      <p />
      <Link to="/">
        <Button variant="primary" className="btn ml-3">Home</Button>
      </Link>
      {!keycloak.authenticated &&
        <Button variant="primary" className="btn ml-3" onClick={() => keycloak.login()}>Login</Button> ||
       keycloak.authenticated &&
       <Button variant="primary" className="btn ml-3" onClick={() => keycloak.logout()}>Logout</Button>
      }
    </div>
  );
}
export default AuthRequest;

