import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { Link } from 'react-router-dom'
import NavDropdown from 'react-bootstrap/NavDropdown'
import AuthStatus from './pages/AuthStatus.js'
import WineSearch from './WineSearch'
import Container from 'react-bootstrap/Container'

const WineNavBar = (props) => {
  return (
    <Navbar bg="light" expand="lg">
      <Container fluid>
      <Navbar.Brand href="/">Wine Cave</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto my-2 my-lg-0">
          <Nav.Link as={Link} to='/'>Home</Nav.Link>
          <NavDropdown title="Account" id="basic-nav-dropdown">
            <NavDropdown.Item as={Link} to='/userinfo'>User info</NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="Wines" id="basic-nav-dropdown">
            <NavDropdown.Item as={Link} to='/getallwines'>List wines</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item as={Link} to='/wineadd'>Add a wine</NavDropdown.Item>
            <NavDropdown.Item as={Link} to='/winereviews'>Wine reviews</NavDropdown.Item>
            <NavDropdown.Item as={Link} to='/flavors'>About flavors</NavDropdown.Item>
          </NavDropdown>
        </Nav>
        <AuthStatus addUserInfo={props.addUserInfo} addUserLog={props.addUserLog} userDetails={props.userDetails}/>
        <WineSearch addWineSearchProps={props.addWineSearchProps}></WineSearch>
      </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default WineNavBar