import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Button from 'react-bootstrap/Button'
import { Link, useLocation} from 'react-router-dom'

const WineReview = (props) => {
  
  let [ isUpdated, setIsUpdated ] = useState(false);
  let [ review, setReview ] = useState("test");
  let location = useLocation();
  let reviewId = new URLSearchParams(location.search).get('reviewId');
  let wineName = new URLSearchParams(location.search).get('wineName');
  
  useEffect(() => {
    if (isUpdated === true) {
      return;
     }
    setIsUpdated(true);

    axios
      .get(`/getwinereviewbyid?reviewId=${reviewId}`)
        .then(response => setReview(response.data));
    
  },[review,reviewId,isUpdated]);


  return (
    <div>
      <hr />
      <h3>Wine name: {wineName}</h3>
      <p />
      {review.reviewText}
      <br />
      <hr />
      Reviewer's score: {review.reviewRate}
      <p />
      <Link to="/">
        <Button variant="primary">Home</Button>
      </Link>
      <Link to={{pathname:"/winereviews"}}>
        <Button variant="primary" className="btn ml-3">Back to all reviews</Button>
      </Link>
      <hr />
    </div>
  );
}
export default WineReview;

