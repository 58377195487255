export const wineDefault = {
    wineId: "empty id",
    name: "Set name",
    color: "Choose color",
    country: "Choose country",
    description: "Tell me more",
    year: "2019",
    grapes: [],
    tags:[]
  };

export const winesDefault = {
  wines: [
    {
      wineDefault
    }
  ]
};

export const wineReviewDefault = {
  reviewId: 'unset',
  wineId: 'unset',
  wineName: 'unset',
  reporterPersonId: 'unset',
  reporterPersonName: 'unset',
  externalUrl: 'unset',
  grapes: [],
  tags:[],
  reviewText: '',
  reviewRate: 2
};

export const wineReviewsDefault = {
  wineReviews: [
    {
      wineReviewDefault
    }
  ]
}

export function checkValueInArray(array, value) {
  if (array.includes(value)) {
    return true;
  }
  return false;
}