import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import axios from 'axios'
import { useKeycloak } from '@react-keycloak/web'

const AuthStatus = (props) => {
  const {keycloak, initialized} = useKeycloak();
  const [userInfo, setUserInfo] = useState({email: props.userDetails.useremail});
  const [notification, setNotification] = useState({message: 'none'});
  const addToUserLog = props.addUserLog;
  const addUserInfo = props.addUserInfo;
  
  // icon https://icons.getbootstrap.com/icons/person-circle/#

  const logOut = () => {
    let userProfile = {
      firstName: 'Anonymous',
      lastName: '',
      email: "none"
    }
    keycloak.logout();
    addUserInfo(userProfile);
    addToUserLog({message: "You logged out"});
  }

  const authInfo = () => {
    if (keycloak.authenticated) {
      return (
        <div className="btn ml-3">
          <span className="section-icon">
            <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-person-circle" fillRule="inherit" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.468 12.37C12.758 11.226 11.195 10 8 10s-4.757 1.225-5.468 2.37A6.987 6.987 0 0 0 8 15a6.987 6.987 0 0 0 5.468-2.63z"/>
              <path fillRule="evenodd" d="M8 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
              <path fillRule="evenodd" d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z"/>
            </svg>
          </span>
          User: {userInfo.fullName}
          <Button variant="primary" className="btn ml-3" onClick={() => logOut()}>Logout</Button>
        </div>
      )
    }
    return (
      <div className="btn ml-3">
        <Button variant="primary" className="btn ml-3" onClick={() => keycloak.login()}>Login</Button>
      </div>
    )
  }
  
  useEffect(() => {
    if (!initialized) {
      return;
    }

    const fetchUserInfo = async () => {
      console.log("Running fetchUserInfo since email is: ", userInfo.email);
      try {
        const userProfile = await keycloak.loadUserProfile();
        let fullName = `${userProfile.firstName} ${userProfile.lastName}`;
        setUserInfo({ ...userProfile, fullName: fullName });
        addUserInfo(userProfile);
        addToUserLog({message: "You logged in"});
        await userExistsInDb(userProfile);
      } catch (err) {
        setNotification({message: err.message });
        addToUserLog({message: err.message });
      }
    };

    const userExistsInDb = async (userProfile) => {
      let fullName = `${userProfile.firstName} ${userProfile.lastName}`;
        await axios.get(`/getreporterbyemail?email=${userProfile.email}`)
        .then(message => {
          console.log(`User ${userProfile.email} exists in database`);
        }).catch(err => {
          if(err.response.status === 404) {
            addReporterPerson(fullName, userProfile.email);
            addToUserLog({message: "Welcome to the database"});
          }
        })
    }

    const addReporterPerson = async (userName, email) => {
      await axios.get(`/addreporter?reportername=${userName}&email=${email}`)
      .then(message => {
        //setAuthMessage(message.data);
        console.log("Returned data from addreporter: ", message.data);
        return message.data;
      }).catch(err => {
        setNotification({message: err.message})
        addToUserLog({message: err.message });
      });
    }

    // If mail set to real address we don't need to login again but is based on passed props.
    // Purpose to not call Cosmos DB for account existance if not neccessary in fetechUserInfo. 
    if (userInfo.email !== 'none') {
      console.log("User email set so no need to set check db again: ",  userInfo.email);
      return;
    }

    if (keycloak.authenticated) {
      fetchUserInfo();
    }
    
  },[keycloak,initialized,keycloak.authenticated,userInfo,addToUserLog,addUserInfo]);

  return (
    <div>
      { authInfo() }
    </div>
  )
}
export default AuthStatus;

