import React, { useState, useEffect, setState } from 'react'
import { wineDefault } from '../Resources'
import axios from 'axios'
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import InputGroup from 'react-bootstrap/InputGroup'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Button from 'react-bootstrap/Button'
import Badge from 'react-bootstrap/Badge'

const WineAdd = (props) => {

  // ******
  // Need to use grape property for temp storage but it's never submitted. 
  // Hence the extension.
  // *****

  let wineExtended = wineDefault;
  wineExtended.grape = '';
  wineExtended.tag = '';
  wineExtended.externalUrl = '';
  
  const [wine, setState] = useState(wineExtended);
  const [sparklingChecked, setSparklingChecked] = useState(false);
  const [greeting, setGreeting] = useState('Add a new wine');
  const [ingredients, setIngredients] = useState(new Map());
  
  const addUserLogMessage = message => {
    props.addUserLog({message: message});
  }

  const genYears = () => {
    let min = 1950;
    let max = 2021;
    let yearsRange = [];
    for(let i = max; i >= min; i -= 1){
       yearsRange.push(i);
    }
    return yearsRange;
  }

  const theYears = genYears();

  const handleChange = e => {
    const { name, value } = e.target;
    setState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSelect = (e,type) => {
    setState(prevState => ({
      ...prevState,
      [type]: e
    }));
  }
  
  const addWine = (event) => {
    event.preventDefault();

    var wineJson = {
      "name": `${wine.name}`,
      "color": `${wine.color}`,
      "country": `${wine.country.toUpperCase()}`,
      "description": `${wine.description}`,
      "year": `${wine.year}`,
      "externalUrl": `${wine.externalUrl}`,
      "grapes": [`${wine.grapes}`],
      "tags": [`${wine.tags}`]
    }
    if (sparklingChecked) {
      wineJson.tags.unshift('sparkling');
    }

    var ingredientsJson = {
      "wineId": "unset",
      "ingredients": []
    }

    // Convert map to array
    if (ingredients.size > 0) {
      ingredientsJson.ingredients = Array.from(ingredients.keys());
    }
    
    axios
      .post("/addwine", wineJson)
      .then(response => {
        if (response.status === 200) {
          setGreeting(`Wine ${wine.name} was added`)
          addUserLogMessage(`Added wine ${wine.name}`);
          setState(wineExtended)
          if (ingredientsJson.ingredients) {
            ingredientsJson.wineId = response.data.wineId;
            axios
            .post("/wine/addwinewithingredient", ingredientsJson)
            .then(response1 => {
              if (response1.status === 200) {
                addUserLogMessage(`Added wine relation to ingredient for wine: ${wine.name}`);
              }
              else {
                addUserLogMessage(`Failed to add wine relation to ingredient for wine: ${wine.name}`)
              }
            })
          }
        }
        else {
          console.log(`Failed to add wine, error: ${response.status} ${response.statusText}`);
          addUserLogMessage(`Failed to add wine ${wine.name}`)
        }
      }).catch(err => {
        console.log(err);
      });
  }

  const addGrape = () => {
    setState(prevState => ({
      ...prevState,
      grapes: prevState.grapes.concat(wine.grape.toLowerCase())
    }));
  }

  const addTag = () => {
    setState(prevState => ({
      ...prevState,
      tags: prevState.tags.concat(wine.tag.toLowerCase())
    }));
  }

  const handleOnClick = e => {
    const { name, value } = e.target;
    setState(prevState => ({
      ...prevState,
      [name]: ''
    }));
  };

  const listGrapes = () => {
    if (wine.grapes) {
      return (
        wine.grapes.map(theGrape =>
          <Badge variant="info" key={theGrape}>{theGrape}</Badge>
        )
      )
    }
  }

  const listTags = () => {
    if (wine.tags) {
      return (
        wine.tags.map(theTag =>
          <Badge variant="info" key={theTag}>{theTag}</Badge>
        )
      )
    }
  }

  const handleCheckboxChange = () => {
    if (sparklingChecked) {
      setSparklingChecked(false);
    }
    if (!sparklingChecked) {
      setSparklingChecked(true);
    }
  }

  const handleIngredientCheckBoxes = (e) => {
    const { name, value } = e.target;
    if (ingredients.has(value)) {
      ingredients.delete(value);
    } else {
      setIngredients(new Map(ingredients.set(value,value)));
    }
  }

  return(
    <div>
      <hr className='topbottom'/>
      <h3>{greeting}</h3>
      <p />
    <Form onSubmit={addWine}>
      <Form.Group controlId="formWineName">
        <Form.Label>Wine name</Form.Label>
        <FormControl type="text" placeholder="Enter name of wine" className="mr-sm-2" onClick={handleOnClick} onChange={handleChange} value={wine.name} name="name" />
        <Form.Text className="text-muted">
          Wine is good.
        </Form.Text>
      </Form.Group>
      <InputGroup className="mb-3">
        <DropdownButton id="dropdown-basic-button-color" title={wine.color} onSelect={e => handleSelect(e,'color')} name="color">
          <Dropdown.Item key="Red" eventKey="red">Red</Dropdown.Item>
          <Dropdown.Item key="White" eventKey="white">White</Dropdown.Item>
          <Dropdown.Item key="Rose" eventKey="rose">Rosé</Dropdown.Item>
        </DropdownButton>
      </InputGroup>
      <Form.Group controlId="formBasicCheckbox">
        <Form.Check type="checkbox" label="Is wine sparkling?" onChange={handleCheckboxChange} value="1" />
      </Form.Group>
      <InputGroup className="mb-3">
        <DropdownButton id="dropdown-basic-button-country" title={wine.country} onSelect={e => handleSelect(e,'country')} name="country">
          <Dropdown.Item key="Austria" eventKey="Austria">Austria</Dropdown.Item>
          <Dropdown.Item key="Australia" eventKey="Australia">Australia</Dropdown.Item>
          <Dropdown.Item key="Chile" eventKey="Chile">Chile</Dropdown.Item>
          <Dropdown.Item key="France" eventKey="France">France</Dropdown.Item>
          <Dropdown.Item key="Germany" eventKey="Germany">Germany</Dropdown.Item>
          <Dropdown.Item key="Italy" eventKey="Italy">Italy</Dropdown.Item>
          <Dropdown.Item key="Spain" eventKey="Spain">Spain</Dropdown.Item>
          <Dropdown.Item key="USA" eventKey="USA">USA</Dropdown.Item>
        </DropdownButton>
      </InputGroup>
      <InputGroup className="mb-3">
        <DropdownButton id="dropdown-basic-button-year" title={wine.year} onSelect={e => handleSelect(e,'year')} name="year">
        {theYears.map(year =>
          <Dropdown.Item key={year} eventKey={year}>{year}</Dropdown.Item>
        )}
        </DropdownButton>
      </InputGroup>
      <Form.Group controlId="formWineGrapes">
        <Form.Label>Grapes</Form.Label>
        <FormControl type="text" placeholder="Add grape sort" className="mr-sm-2" onClick={handleOnClick} onChange={handleChange} value={wine.grape} name="grape" />
        <Form.Text className="text-muted">
          You can add multiple grapes one at a time using Add grape button.
        </Form.Text>
        <Button variant="primary" onClick={addGrape}>Add grape</Button>
        <p/>
        Grapes:
        <h4>
          <p>
            {listGrapes()}
          </p>
        </h4>
      </Form.Group>
      <Form.Group controlId="formWineDescription">
        <Form.Label>Wine description</Form.Label>
        <FormControl type="text" placeholder="Enter a description of the wine" className="mr-sm-2" onChange={handleChange} value={wine.description} name="description" />
        <Form.Text className="text-muted">
          Max 430 chars.
        </Form.Text>
      </Form.Group>
      <Form.Group controlId="formIngredientsCheckbox">
        <Form.Check type="checkbox" label="Beef" onChange={e => handleIngredientCheckBoxes(e,'beef')} value="beef" />
        <Form.Check type="checkbox" label="Cheese" onChange={e => handleIngredientCheckBoxes(e,'cheese')} value="cheese" />
        <Form.Check type="checkbox" label="Dessert" onChange={e => handleIngredientCheckBoxes(e,'dessert')} value="dessert" />
        <Form.Check type="checkbox" label="Fish" onChange={e => handleIngredientCheckBoxes(e,'fish')} value="fish" />
        <Form.Check type="checkbox" label="Lamb" onChange={e => handleIngredientCheckBoxes(e,'lamb')} value="lamb" />
        <Form.Check type="checkbox" label="Poultry" onChange={e => handleIngredientCheckBoxes(e,'poultry')} value="poultry" />
        <Form.Check type="checkbox" label="Pork" onChange={e => handleIngredientCheckBoxes(e,'pork')} value="pork" />
        <Form.Check type="checkbox" label="Seafood" onChange={e => handleIngredientCheckBoxes(e,'seafood')} value="seafood" />
        <Form.Check type="checkbox" label="Game" onChange={e => handleIngredientCheckBoxes(e,'game')} value="game" />
        <Form.Check type="checkbox" label="Vegetarian" onChange={e => handleIngredientCheckBoxes(e,'vegetarian')} value="vegetarian" />
      </Form.Group>
      <Form.Group controlId="formWineTags">
        <Form.Label>Wine tags</Form.Label>
        <FormControl type="text" placeholder="Add tags" className="mr-sm-2" onClick={handleOnClick} onChange={handleChange} value={wine.tag} name="tag" />
        <Form.Text className="text-muted">
          You can add multiple tags one at a time using Add tags button.
        </Form.Text>
        <Button variant="primary" onClick={addTag}>Add tag</Button>
        <p/>
        Tags:
        <h4>
          <p>
            {listTags()}
          </p>
        </h4>
      </Form.Group>
      <Form.Group controlId="formWineUrlToVendor">
        <Form.Label>Add an external link (optional)</Form.Label>
        <FormControl type="text" placeholder="Enter name of wine" className="mr-sm-2" onClick={handleOnClick} onChange={handleChange} value={wine.externalUrl} name="externalUrl" />
        <Form.Text className="text-muted">
          Example, link to where you can buy or try this wine.
        </Form.Text>
      </Form.Group>
      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Form>
    <hr className='topbottom'/>
    </div>
  )
}

export default WineAdd;