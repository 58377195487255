import React from 'react'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'

const UserInfo = (props) => {
  
  const logItems = () => {
    let logEntries = [];
    props.userLog.forEach((logData, index) => {
      logEntries.push(<li key={index}>{logData.logDate} {logData.message}</li>);
    });
    return (
      logEntries
    )
  }

  return (
    <div>
      <hr className='topbottom'/>
      <h3>User information</h3>
        <p />
        Username: {props.userDetails.username}
        <p />
        Email: {props.userDetails.useremail}
        <p />
        <hr className='topbottom'/>
        <h4>Events</h4>
        <ul>
          {logItems()}
        </ul>
      <hr className='topbottom'/>
      <p />
      <Link to="/">
        <Button variant="primary" className="btn ml-3">Home</Button>
      </Link>
    </div>
  )
}
export default UserInfo;

