import React from 'react'
import { ListGroup, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const AboutFlavors = (props) => {
  return(
    <div className="aboutFlavors">
      <hr className='topbottom'/>
      <div className="row">
        <div className="col-6">
          <h3>Grapes and flavors</h3>
          <ListGroup>
            <ListGroup.Item>
              <h4>Grenache</h4>
              <b>Wine type made by this grape:</b> Red
              <br />
              <b>Flavours:</b> Spice, Cherry, Raspberry, Lavender
            </ListGroup.Item>
            <ListGroup.Item>
              <h4>Cabernet sauvignon</h4>
              <b>Wine type made by this grape:</b> Red
              <br />
              <b>Flavours:</b> Cedar, Herbs, Pruns, Green pepper, Blackcurrants
            </ListGroup.Item>
            <ListGroup.Item>
              <h4>Merlot</h4>
              <b>Wine type made by this grape:</b> Red
              <br />
              <b>Flavours:</b> Pruns, Blackcurrant, Cherry, Herbs
            </ListGroup.Item>
            <ListGroup.Item>
              <h4>Syrah or Shiraz</h4>
              <b>Wine type made by this grape:</b> Red
              <br />
              <b>Flavours:</b> Blackberry, Viola, Juniper, white peppar, Charcuterie
            </ListGroup.Item>
            <ListGroup.Item>
              <h4>Pinot noir</h4>
              <b>Wine type made by this grape:</b> Red
              <br />
              <b>Flavours:</b> Spice, Herbs, Strawberry, Sandalwood
            </ListGroup.Item>
            <ListGroup.Item>
              <h4>Primitivo or Zinfandel</h4>
              <b>Wine type made by this grape:</b> Red
              <br />
              <b>Flavours:</b> Black peppar, Blueberry, Prune, Blackberry, Mint
            </ListGroup.Item>
          </ListGroup>
        </div>
        <div className="col-6">
          <img src="grape-hands.jpg" className="img-fluid rounded" alt="..." />
          <p />
          <img src="grape-hat.jpg" className="img-fluid rounded" alt="..." />
        </div>
      </div>
      <hr className='topbottom'/>
      <Link to="/">
        <Button variant="primary" className="btn ml-3">Home</Button>
      </Link>
    </div>
  )
}

export default AboutFlavors;