import React from 'react'
import { Route, Routes } from 'react-router-dom'
import WineNavBar from './NavBar'
import WineHome from './WineHome'
import WineAdd from './pages/WineAdd'
import WineDisplay from './WineDisplay'
import WineReco from './WineReco'
import { Link } from 'react-router-dom'
import AuthRequest from './AuthRequest'
import Error from './Error'
import Keycloak from "keycloak-js"
import { ReactKeycloakProvider } from '@react-keycloak/web'
import setupAxiosInterceptors from './config/interceptor'
import UserInfo from './pages/UserInfo'
import moment from 'moment'
import WineReviewAdd from './pages/WineReviewAdd'
import WineReviews from './pages/WineReviews'
import WineReview from './pages/WineReview'
import WineList from './pages/WineList'
import AboutFlavors from './pages/AboutFlavors'

export const keycloak = new Keycloak({
  url: window.ENV.KEYCLOAK_HOST + '/auth', 
  realm: 'winecave', 
  clientId: 'wc-client'
})

const keycloakProviderInitConfig = {
  onLoad: 'check-sso',
  silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
  enableLogging: false,
}

setupAxiosInterceptors();

const logToken = false;

class WineContainer extends React.Component {
  constructor(props) {
    super();
    this.state = {
      wines: [
        {
          wineId: 1,
          name: "Test vin",
          color: "red",
          country: "FRANCE"
        }
      ],
      userdetails: {
        username: 'Anonymous',
        useremail: 'none'
      },
      userlog: [
        {
          logDate: moment().format("YYYY-MM-DD hh:mm:ss"),
          message: "Log started"
        }
      ]
    };
  }

  addWine = wine => {
    const newWine = {
      wineId: wine.wineId,
      name: wine.name,
      color: wine.color,
      country: wine.country
    };
    this.setState({
      wines: [...this.state.wines, newWine]
    });
  };

  addWines = wines => {
    this.setState({
      wines: [...wines]
    });
  };

  addUserInfo = userInfo => {
    this.setState({
      userdetails: {
          username: `${userInfo.firstName} ${userInfo.lastName}`,
          useremail: userInfo.email
        }
      });
  };

  addUserLog = logData => {
    let dateNow = moment().format("YYYY-MM-DD hh:mm:ss");
    let newLogItem = {
      logDate: dateNow,
      message: logData.message
    }
    this.setState({
      userlog: [...this.state.userlog, newLogItem]
    });
  };

  onKeycloakEvent = (event, error) => {
    console.log('onKeycloakEvent', event, error)
  }

  onKeycloakTokens = (tokens) => {
    if (logToken === true) {
      console.log('onKeycloakTokens', tokens)
    }
  }

  render() {
    return (
      <ReactKeycloakProvider
        authClient={ keycloak }
        initConfig={ keycloakProviderInitConfig }
        onEvent={ this.onKeycloakEvent }
        onTokens={ this.onKeycloakTokens }
      >
      <div className="container">
        <WineNavBar addWineSearchProps={this.addWines} addUserInfo={this.addUserInfo} addUserLog={this.addUserLog} userDetails={this.state.userdetails}/>
        <main>
          <Routes>
            <Route path='/' element={<WineHome />} />
            <Route path='/winesearch' element={
              <><hr className='topbottom' /><h3>Your search results:</h3><ul>
                  {this.state.wines.map(wine => (
                    <li key={wine.name}> Wine name:&nbsp;
                      <Link to={{ pathname: "/wineDisplay", search: "?wineId=" + wine.wineId }}>{wine.name}</Link>
                      <ul>
                        <li key={wine.color}>Color: {wine.color}</li>
                        <li key={wine.country}>Country: {wine.country}</li>
                      </ul>
                    </li>
                  ))}
                </ul><hr className='topbottom' /></>
            } />
            <Route path='/wineadd' element={<WineAdd addUserLog={this.addUserLog}/>} />
            <Route path='/winereviewadd' element={<WineReviewAdd userEmail={this.state.userdetails.useremail} addUserLog={this.addUserLog}/>} />
            <Route path="/winedisplay" element={<WineDisplay allWines={this.state.wines} userEmail={this.state.userdetails.useremail} addUserLog={this.addUserLog}/>} />
            <Route path="/winereview" element={<WineReview />} />
            <Route path="/winereviews" element={<WineReviews />} />
            <Route path="/authrequest" element={<AuthRequest />} />
            <Route path='/winereco' element={<WineReco />} />
            <Route path='/getallwines' element={<WineList />} />
            <Route path='/userinfo' element={<UserInfo userDetails={this.state.userdetails} userLog={this.state.userlog} />} />
            <Route path='/flavors' element={<AboutFlavors />} />
            <Route element={<Error />} />
          </Routes>
        </main>
      </div>
      </ReactKeycloakProvider>
    );
  }
}
export default WineContainer