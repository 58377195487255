import React, { useState, useEffect } from 'react'
import axios from 'axios'
import ListGroup from 'react-bootstrap/ListGroup'
import { Link } from 'react-router-dom'
import { checkValueInArray } from '../Resources'

const WineList = () => {

  let [wines, setWines] = useState([]);
  let [request, setRequest] = useState(false);

  useEffect(() => {
    if (request === true) {
      return;
    }
    setRequest(true);
    axios
      .get(`/getallwines`)
        .then(response => response.data.forEach(wine => {
          setWines(wines => [...wines, wine]);
        }));
  },[wines,request]);

  const isSparkling = (array,value) => {
    if (checkValueInArray(array,value)) {
      return "Sort: sparkling";
    }
  }

  return(
    <div className="WineList">
      <hr className='topbottom'/>
      <h3>Wine Cave list of wines</h3>
      <p/>
      <ListGroup className="list-group-flush">
      {wines.map(wine => (
        <ListGroup.Item key={wine.wineId}>
           Wine name: <Link to={{pathname:"/wineDisplay",search: "?wineId=" + wine.wineId}}>{wine.name}</Link>
           <br />
           Color: {wine.color}
           <br />
           {isSparkling(wine.tags,"sparkling")}
        </ListGroup.Item>
      ))}
      </ListGroup >
      <hr className='topbottom'/>
    </div>
  )
}

//export default withRouter(WineList);
export default WineList;