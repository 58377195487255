import React, { Component } from 'react'
import Alert from 'react-bootstrap/Alert'

class Error extends Component {
  render() {
    return (
      <div>
        <Alert variant='info'>
          Ooops! This error can't possibly happen, right?
          <p />
          <Alert.Link href="/">Return to start page</Alert.Link>
        </Alert>
      </div>
    );
  }
}

export default Error