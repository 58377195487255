import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'

const WineSearch = (props) => {
  const winesStartState = {
    wines: [
      {
        wineId: "empty id",
        name: "empty name",
        color: "empty",
        country: "empty"
      }
    ]
  }

  const [searchState, setSearchState] = useState('');
  const [wines, setWinesState] = useState(winesStartState);
  const [submitted, setSubmitted] = useState(false);
  let navigate = useNavigate();
   
  const onChange = e => {
    setSearchState(e.target.value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setSubmitted(true);
  }

  useEffect(() => {
    
    if (submitted === false) {
      return;
    }

    setSubmitted(false);
    
    async function getThemWines() {
      let response = await axios
        .get(`/getwines?name=${searchState}`);
      return response.data;
    }

    let searchResult = getThemWines();

    searchResult.then( results => {
        setWinesState({wines: results});
        props.addWineSearchProps(results);
        navigate('/winesearch');
      }
    );
  },[navigate,submitted,props,searchState,wines]);
  
  return(
    <Form className='d-flex' onSubmit={handleSubmit}>
      <FormControl type="text" placeholder="Wine name, color, grapes" className="mr-sm-2" value={searchState} onChange={onChange} />
      <Button className="ms-sm-2" variant="outline-success" type="submit">Search</Button>
    </Form>
  )
}

export default WineSearch;