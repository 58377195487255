import axios from 'axios';
import { keycloak } from "../WineContainer";

const setupAxiosInterceptors = () => {

  const TIMEOUT = 60 * 1000;
  axios.defaults.timeout = TIMEOUT;
  axios.defaults.baseURL = window.ENV.BACKEND_HOST;
  const onRequestSuccess = config => {
    const token = keycloak.token;
    //config.headers['Access-Control-Allow-Origin'] = config.baseURL;
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  } 
  const onResponseSuccess = response => response; 
  const onResponseError = err => {
    const status = err.status || (err.response ? err.response.status : 0);
    if (status === 403 || status === 401) {
      // onUnauthenticated();
      //toast.error("Måste vara inloggad för att göra anropet: " + status)
      console.log("You must be logged in: ", status);
    }
    return Promise.reject(err);
  };
  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;