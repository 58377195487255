import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import InputGroup from 'react-bootstrap/InputGroup'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Button from 'react-bootstrap/Button'
import Toast from 'react-bootstrap/Toast'
import ToastHeader from 'react-bootstrap/ToastHeader'
import ToastBody from 'react-bootstrap/ToastBody'

const WineReviewAdd = (props) => {
  const wineReviewDefault = {
    wineId: 'unset',
    reporterPersonId: 'unset',
    reviewText: '',
    reviewRate: 2
  }

  const navigate = useNavigate();
  const [wineReview, setWineReview] = useState(wineReviewDefault);
  const reporterEmail = props.userEmail;
  const addUserLogMessage = message => {
    props.addUserLog({message: message});
  }
  let location = useLocation();
  let wineId = new URLSearchParams(location.wineid).get('wineId');
  let wineName = new URLSearchParams(location.winename).get('wineName');
  const [show, setShow] = useState(false);


  const handleChange = e => {
    const { name, value } = e.target;
    setWineReview(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSelect = (e,type) => {
    setWineReview(prevState => ({
      ...prevState,
      [type]: e
    }));
  }

  const getReporterId = () => {
    return axios.get(`/getreporterbyemail?email=${reporterEmail}`)
      .then(message => {
        return message.data.personId;
      }).catch(err => {
        console.log("Error getting reporter by email: ", err.message);
      });
  }
  
  const addReview = (event) => {
    event.preventDefault();
    getReporterId().then(personId => {
      setWineReview(prevState => ({
        ...prevState,
        reporterPersonId: personId,
        wineId: wineId
      })
      );
    })
  }

  const wineReviewClicked = () => {
    setWineReview(prevState => ({
      ...prevState,
      reviewText: ''
    }));
  }

  const saveWineReview = () => {
    axios
      .post("/addwinereview", wineReview)
        .then(response => {
          if (response.status === 200) {
            addUserLogMessage(`Added a review for ${wineName}`);
          }
          else {
            addUserLogMessage(`Failed to add review for ${wineName}`);
            console.log(`Failed to add wine review, error: ${response.status} ${response.statusText}`);
          }
        })
        .catch(err => {
          console.log(err);
        });
  }

  const showSaveToast = () => {
    return (
      <div className="notification-container">
        <Toast onClose={() => navigate('/')} show={show} delay={7000} autohide>
          <ToastHeader>
          <img src="holder.js/60x60?text=%20" className="rounded mr-auto" alt="" />
            <strong className="mr-auto">Review of wine {wineName} saved <span role='img' aria-label='champagne'>&#127870;</span></strong>
          </ToastHeader>
          <ToastBody>Thank you for reviewing {wineName}, you will be redirect to the startpage </ToastBody>
        </Toast>
      </div>
    );
  }

  useEffect(() => {
    if (show === true) {
      return;
    }
    // Submit triggers addReview, this runs when state changes making actual post to API
    if (wineReview.reporterPersonId !== 'unset') {
      saveWineReview();
      setShow(true);
    }
  },[wineReview,saveWineReview,show]);
  
  return (
    <div>
      <hr className='topbottom'/>
      <h3>Add a review of a wine</h3>
      <p />
      {showSaveToast()}
    <Form onSubmit={addReview}>
      <Form.Group controlId="formWineName">
        <Form.Label>Wine name</Form.Label>
        <FormControl type="text" placeholder="Enter name of wine" className="mr-sm-2" onChange={handleChange} value={wineName} name="name" />
        <Form.Text className="text-muted">
          Wine is good.
        </Form.Text>
      </Form.Group>
      Rate the wine 1 - 5 (best)
      <InputGroup className="mb-3">
        <DropdownButton id="dropdown-basic-button-rate" title={wineReview.reviewRate} onSelect={e => handleSelect(e,'reviewRate')} name="Rate">
          <Dropdown.Item key="1" eventKey="1">1</Dropdown.Item>
          <Dropdown.Item key="2" eventKey="2">2</Dropdown.Item>
          <Dropdown.Item key="3" eventKey="3">3</Dropdown.Item>
          <Dropdown.Item key="4" eventKey="4">4</Dropdown.Item>
          <Dropdown.Item key="5" eventKey="5">5</Dropdown.Item>
        </DropdownButton>
      </InputGroup>
      <Form.Group controlId="formWineReview">
        <Form.Label>Wine review</Form.Label>
        <FormControl as="textarea" placeholder="Write your review of the wine" rows={5} className="mr-sm-2" onClick={wineReviewClicked} onChange={handleChange} value={wineReview.reviewText} name="reviewText" />
        <Form.Text className="text-muted">
          Max 430 chars.
        </Form.Text>
      </Form.Group>
      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Form>
    <hr className='topbottom'/>
    </div>
  )
}

export default WineReviewAdd;